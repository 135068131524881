/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 52번째 에러 로그에서는 Apple 고객 서비스 경험  등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.elgato.com/en/gaming/cam-link-4k"
  }, "Cam Link 4K | elgato.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/yun_seoul/status/1174524730579120128"
  }, "ゆん＠韓国 on Twitter: \"삼성 서비스 센터에서 나와서 18년 8k TV (85Q900R)의 ... - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr"
  }, "공식 Apple 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2018/"
  }, "RubyKaigi 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/retail/garosugil/"
  }, "가로수길 - Apple Store - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://japanese.engadget.com/2019/01/07/apple-1-25/"
  }, "(일본어) 애플 스토어 센다이 점 1월 25일에 폐점 - Engadget")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr/HT201296"
  }, "iPhone X 모델 식별하기 - Apple 지원")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
